import React, { FC } from 'react'
import { graphql, PageProps } from 'gatsby'
import styled from 'styled-components'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import MainPage from './main-page'

const StyContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const StyImageBox = styled.div`
  margin: 4rem 4rem;
  width: min(800px, 100vw - 4rem);
  height: min-content;

  box-shadow: 0 0.2rem 0.3rem 0 rgba(0, 0, 0, 0.8);

  @media only screen and (max-width: 1200px) {
    width: calc(100vw - 2rem);
    margin: 2rem 0rem;
    max-height: unset;
}
`

const StyVideo = styled.video`
  margin: 4rem 4rem;
  width: min(800px, 100vw - 4rem);

  box-shadow: 0 0.2rem 0.3rem 0 rgba(0, 0, 0, 0.8);

  @media only screen and (max-width: 1200px) {
    width: calc(100vw - 2rem);
    margin: 2rem 0rem;
    max-height: unset;
}
`

type PostPageContext = {
  dir: string
}

type PostData = {
  pictures: {
    nodes: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData
      } | null
      id: string
    }[]
  }
  videos: {
    nodes: {
      publicURL: string
    }[]
  }
}

const Post: FC<PageProps<PostData, PostPageContext, {}>> = ({ data }) => {
  return (
    <MainPage>
      <StyContainer>
        {
        data.pictures.nodes.map(picture => {
          if (picture.childImageSharp) {
            return (
              <StyImageBox key={picture.id}>
                <GatsbyImage image={picture.childImageSharp.gatsbyImageData} alt="" />
              </StyImageBox>
            )
          }
          return null
        })
      }
        {
        data.videos.nodes.map(video => (
          <StyVideo controls key={video.publicURL}>
            <source src={`../${video.publicURL}`} type="video/mp4" />
          </StyVideo>
        ))
      }
      </StyContainer>
    </MainPage>
  )
}

export const query = graphql`query ($dir: String!) {
  pictures: allFile(
    filter: {extension: {regex: "/jpg|png/"}, relativeDirectory: {eq: $dir}, name: {nin: "cover"}}
  ) {
    nodes {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
      }
    }
  }
  videos: allFile(
    filter: {extension: {regex: "/mp4/"}, relativeDirectory: {eq: $dir}, name: {nin: "cover"}}
  ) {
    nodes {
      publicURL
    }
  }
}
`

export default Post
